
const moduleState = {
  language: 'cn',
  isPC: false
}

const getters = {
  globalLanguageGetter: state => state.language,
  isPC: state => state.isPC
}

const actions = {
  changeLanguage ({ state }) {
    const { language } = state
    if (language === 'cn') {
      state.language = 'en'
    } else {
      state.language = 'cn'
    }
  },
  checkPlatform({ state }, status) {
    state.isPC = status
  }
}

export default {
  state: moduleState,
  getters,
  actions
}

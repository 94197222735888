<template>
  <div class="home">
    <HCom />
  </div>
</template>

<script>
// @ is an alias to /src
import HCom from '@/components/H_Com.vue'

export default {
  name: 'HomeView',
  components: {
    HCom
  }
}
</script>

<style lang="less" scoped>
.home {
	height: 100%;
}
</style>

<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
			isPcPlatform: false
    }
  },
  methods: {
    ...mapActions(['checkPlatform']),
    judgePlatform() {
      const isPc = (document.documentElement.clientWidth || document.body.clientWidth) > 767
      this.isPcPlatform = isPc
      this.checkPlatform(isPc)
    }
  },
  mounted() {
    window.onresize = () => {
      this.judgePlatform()
    }
		this.judgePlatform();
    window.onload = function() {
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault()
      })
    }
  },
  destroyed() {
     window.onresize = null
  }
}

</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
}
</style>

<template>
	<div :class="['boredice_back', { 'boredice_back_m': !isPC }]" id="boredice_back">
		<video
			src="https://static.ice.design/boredice.mp4"
			class="video"
			autoplay
			loop
			muted
			controlslist="nodownload"
			id="videoplayer"
			x5-playsinline="true"
			playsinline="true"
			webkit-playsinline="true"
			v-if="isPC"
		></video>
		<div v-else class="m_boredice"></div>
		<!-- <img v-else src="../assets//m_boredice.gif" alt="" class="m_boredice"> -->
		<p class="text text1">boredice</p>
		<p class="text text2">Why so serious ?</p>
		<p :class="['text_copyright', isPC ? 'text3' : 'text4']">copyright © boredice</p>
		<a class="twitter" href="https://twitter.com/boredice2018" target="_blank"></a>
	</div>
</template>

<script>
// import $ from 'jquery';
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters(['isPC'])
  },
	mounted () {
		this.$nextTick(() => {
			console.log("isPC", this.isPC);
			$.snowFall({
				//创建粒子数量，密度
				particleNo: this.isPC ? 300 : 150,
				//粒子下拉速度
				particleSpeed:30,
				//粒子在垂直（Y轴）方向运动范围
				particleY_Range:1300,
				//粒子在垂直（X轴）方向运动范围
				particleX_Range:1000,
				//是否绑定鼠标事件
				bindMouse: true,
				//相机离Z轴原点距离
				zIndex:600,
				//摄像机视野角度
				angle:55,
				wind_weight:0
			});
			// 播放器区域禁用鼠标右键
			$('#videoplayer').bind('contextmenu',function() {return false; });
			let voice = document.getElementById('videoplayer');
			if (typeof WeixinJSBrdgeReady == "object" && typeof WeixinJSBridge.invoke == "function"){
				voice.play()
			} else {
				if (document.addEventListener) {
					document.addEventListener("WeixinJSBridgeReady", function () {
						voice.play();
					}, false);
				} else if (document.attachEvent) {
					document.attachEvent("WeixinJSBridgeReady", function () {
						voice.play();
					});
					document.attachEvent("onWeixinJSBridgeReady", function () {
						voice.play();
					});
				}
			}
		});
	}
}
</script>

<style lang="less">
	.boredice_back {
		height: 100%;
		position: relative;
		overflow: hidden;
		// .view {
		// 	background: url("../assets/background.jpg");
		// 	background-size: cover;
		// 	background-position: center;
		// 	background-repeat: no-repeat;
		// 	height: 100%;
		// 	overflow-x: hidden;
		// }
		.video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		.m_boredice {
			width: 100%;
			height: 100%;
			background: url("https://static.ice.design/m_boredice.gif");
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
		.container {
			width: 100%;
    	height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		.text {
			font-family: "Supercell-Magic";
		}
		.text1 {
			font-size: 28px;
			top: 20px;
		}
		.text2 {
			top: 50%;
			transform: translateY(-50%);
			font-size: 72px;
		}
		.text, 
		.text_copyright {
			color: #FFF;
			position: absolute;
			left: 32px;
			z-index: 2;
		}
		.text3 {
			font-size: 15px;
			font-family: Microsoft YaHei;
			font-weight: 600;
			color: #FFFFFF;
			bottom: 25px;
		}
		.twitter {
			position: absolute;
			top: 23px;
			right: 28px;
			z-index: 2;
			display: inline-block;
			width: 29px;
			height: 24px;
			background: url("../assets/twitter.png");
			background-size: cover!important;;
			background-repeat: no-repeat!important;
		}
	}
	.boredice_back_m {
		.text {
			left: 20px;
		}
		.text1 {
			font-size: 28px;
			top: 32px;
			line-height: 22px;
		}
		.text2 {
			font-size: 55px;
			text-align: left;
			top: 33%;
		}
		.text4 {
			font-size: 12px;
			bottom: 15px;
			left: 20px;
			font-family: 'Heiti SC';
		}
		.twitter {
			width: 22.5px;
			height: 19px;
			background: url("../assets/twitter_m.png");
			right: 18px;
			top: 30px;
		}
	}
</style>